import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { BranchEnum } from "../../../models/emums/branchEnum";
import { Roles } from "../../../models/emums/roles";
import { SettlementDocumentRecalculatingTypeEnum } from "../../../models/emums/settlement/settlementDocumentRecalculatingTypeEnum";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import { SettlementPaymentTypeEnum } from "../../../models/emums/settlement/settlementPaymentTypeEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorBankAccountRestModel from "../../../models/rest/contractor/contractorBankAccountRestModel";
import ContractorDetailsForSettlementRestModel from "../../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import CurrencyRateRestModel from "../../../models/rest/register/currencyRateRestModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementIncomeDocumentAttachmentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentAttachmentRestModel";
import SettlementIncomeDocumentItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";
import SettlementIncomeDocumentMementoItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentMementoItemRestModel";
import SettlementIncomeDocumentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementDocumentSaveResponseRestModel from "../../../models/rest/settlement/settlementDocumentSaveResponseRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import SettlementDocumentTypeRestModel from "../../../models/rest/settlement/settlementDocumentTypeRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import router from "../../../router";
import SupervisorDialog from "../supervisor-dialog.vue";
import AttachmentDialog from "./dialog/attachment-dialog.vue";
import IncomeDocumentItemDialog from "./dialog/income-document-item-dialog.vue";

export interface IncomeDocumentInvoiceTypeModel {
    text: string;
    value: number;
    disabled: boolean;
}

@Component({
    components: {
        "attachment-dialog": AttachmentDialog,
        "confirm-dialog": ConfirmDialog,
        "income-document-item-dialog": IncomeDocumentItemDialog,
        "supervisor-dialog": SupervisorDialog,
    }
})


export default class IncomeDocument extends Vue {
    SettlementDocumentRecalculatingTypeEnum = SettlementDocumentRecalculatingTypeEnum;
    isIncomeDocumentNew = false;
    isIncomeDocumentCorrectionNew = false;
    isIncomeDocumentCorrected = false;
    toolbarTitle = "";
    contractorSearch = "";
    issueDateLabel = this.$t("incomeDocument.issueDate");
    saleDateLabel = this.$t("incomeDocument.saleDate");
    paymentDateLabel = this.$t("incomeDocument.paymentDate");
    plnCurrency = {} as CurrencyRestModel | undefined;
    discountEnabled = false;
    incomeDocumentItemsLengthError = null as string | null;
    incomeDocumentItemDescriptionError = null as string | null;
    incomeDocumentItemPriceError = null as string | null;
    incomeDocumentItemTaxTypeIdError = null as string | null;
    incomeDocumentAdditionalListPagerOptions = {
        itemsPerPage: -1,
    };
    incomeDocumentItemCorrectionDifferenceList = [] as SettlementIncomeDocumentItemRestModel[];
    incomeDocumentItemSummaryList = [] as SettlementIncomeDocumentItemRestModel[];
    assignSupervisorWasClicked = false;
    previouslyAssignedSupervisorIdcs = [] as number[];
    lastIncomeDocumentMementoPoint: number = 0;
    incomeDocumentMementoSelectedPoint: number = 0;

    rules = {
        branchId: [
            () => {
                const error = this.validationError.BranchId != undefined ? this.validationError.BranchId[0] : "";
                return error == "" || error;
            }],
        documentNumber: [
            () => {
                const error = this.validationError.DocumentNumber != undefined ? this.validationError.DocumentNumber[0] : "";
                return error == "" || error;
            }],
        documentLanguage: [
            () => {
                const error = this.validationError.DocumentLanguage != undefined ? this.validationError.DocumentLanguage[0] : "";
                return error == "" || error;
            }],
        contractor: [
            () => {
                const error = this.validationError.Contractor != undefined ? this.validationError.Contractor[0] : "";
                return error == "" || error;
            }],
        contractorTinPrefix: [
            () => {
                const error = this.validationError.ContractorTinPrefix != undefined ? this.validationError.ContractorTinPrefix[0] : "";
                return error == "" || error;
            }],
        contractorTin: [
            () => {
                const error = this.validationError.ContractorTin != undefined ? this.validationError.ContractorTin[0] : "";
                return error == "" || error;
            }],
        contractorName: [
            () => {
                const error = this.validationError.ContractorName != undefined ? this.validationError.ContractorName[0] : "";
                return error == "" || error;
            }],
        contractorSymbol: [
            () => {
                const error = this.validationError.ContractorSymbol != undefined ? this.validationError.ContractorSymbol[0] : "";
                return error == "" || error;
            }],
        contractorStreetAndNumber: [
            () => {
                const error = this.validationError.ContractorStreetAndNumber != undefined ? this.validationError.ContractorStreetAndNumber[0] : "";
                return error == "" || error;
            }],
        contractorCountry: [
            () => {
                const error = this.validationError.ContractorCountry != undefined ? this.validationError.ContractorCountry[0] : "";
                return error == "" || error;
            }],
        contractorPostalCode: [
            () => {
                const error = this.validationError.ContractorPostalCode != undefined ? this.validationError.ContractorPostalCode[0] : "";
                return error == "" || error;
            }],
        contractorCity: [
            () => {
                const error = this.validationError.ContractorCity != undefined ? this.validationError.ContractorCity[0] : "";
                return error == "" || error;
            }],
        paymentTerm: [
            () => {
                const error = this.validationError.PaymentTerm != undefined ? this.validationError.PaymentTerm[0] : "";
                return error == "" || error;
            }],
        currencyCode: [
            () => {
                const error = this.validationError.CurrencyCode != undefined ? this.validationError.CurrencyCode[0] : "";
                return error == "" || error;
            }],
        currencyRate: [
            () => {
                const error = this.validationError.CurrencyRate != undefined ? this.validationError.CurrencyRate[0] : "";
                return error == "" || error;
            }],
        incomeDocumentStatusId: [
            () => {
                const error = this.validationError.IncomeDocumentStatusId != undefined ? this.validationError.IncomeDocumentStatusId[0] : "";
                return error == "" || error;
            }],
        incomeDocumentTypeId: [
            () => {
                const error = this.validationError.IncomeDocumentTypeId != undefined ? this.validationError.IncomeDocumentTypeId[0] : "";
                return error == "" || error;
            }],
        documentTypeId: [
            () => {
                const error = this.validationError.DocumentTypeId != undefined ? this.validationError.DocumentTypeId[0] : "";
                return error == "" || error;
            }],
        incomeDocumentInvoiceTypeId: [
            () => {
                const error = this.validationError.IncomeDocumentInvoiceTypeId != undefined ? this.validationError.IncomeDocumentInvoiceTypeId[0] : "";
                return error == "" || error;
            }],
        paymentTypeId: [
            () => {
                const error = this.validationError.PaymentTypeId != undefined ? this.validationError.PaymentTypeId[0] : "";
                return error == "" || error;
            }],
        publicCorrectionReasonId: [
            () => {
                const error = this.validationError.PublicCorrectionReasonId != undefined ? this.validationError.PublicCorrectionReasonId[0] : "";
                return error == "" || error;
            }],
        bankAccountId: [
            () => {
                const error = this.validationError.BankAccountId != undefined ? this.validationError.BankAccountId[0] : "";
                return error == "" || error;
            }],
        bankAccountVatId: [
            () => {
                const error = this.validationError.BankAccountVatId != undefined ? this.validationError.BankAccountVatId[0] : "";
                return error == "" || error;
            }],
        documentRecalculatingType: [
            () => {
                const error = this.validationError.DocumentRecalculatingType != undefined ? this.validationError.DocumentRecalculatingType[0] : "";
                return error == "" || error;
            }],
        issueDate: [
            () => {
                const error = this.validationError.IssueDate != undefined ? this.validationError.IssueDate[0] : "";
                return error == "" || error;
            }],
        saleDate: [
            () => {
                const error = this.validationError.SaleDate != undefined ? this.validationError.SaleDate[0] : "";
                return error == "" || error;
            }],
        incomeDocumentItems: [
            () => {
                const error = this.validationError.IncomeDocumentItems != undefined ? this.validationError.IncomeDocumentItems[0] : "";
                return error == "" || error;
            }],
        incomeDocumentItemDescription: [
            () => {
                const error = this.validationError.Description != undefined ? this.validationError.Description[0] : "";
                return error == "" || error;
            }],
        incomeDocumentItemPrice: [
            () => {
                const error = this.validationError.Price != undefined ? this.validationError.Price[0] : "";
                return error == "" || error;
            }],
    }

    incomeDocumentItemListHeaders = [
        {
            text: "",
            align: "start",
            value: "sort",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemDescription"),
            align: "start",
            value: "description",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemDescriptionDetailed"),
            align: "start",
            value: "descriptionDetailed",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemQuantity"),
            align: "start",
            value: "quantity",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemUnit"),
            align: "start",
            value: "unit",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemPrice"),
            align: "right",
            value: "price",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemNettoValue"),
            align: "right",
            value: "netto",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemTaxRate"),
            align: "right",
            value: "taxRate",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemTaxValue"),
            align: "right",
            value: "tax",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemBruttoValue"),
            align: "right",
            value: "brutto",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemPriceInPln"),
            align: "right",
            value: "pricePln",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemNettoValueInPln"),
            align: "right",
            value: "nettoPln",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemTaxValueInPln"),
            align: "right",
            value: "taxPln",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.itemBruttoValueInPln"),
            align: "right",
            value: "bruttoPln",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    incomeDocumentSupervisorListHeaders = [
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "orderId",
            sortable: false,
        },
        {
            text: this.$t("supervisor.route"),
            align: "start",
            value: "route",
            sortable: false,
        },
        {
            text: this.$t("supervisor.accountingComment"),
            align: "start",
            value: "accountingComment",
            sortable: false,
        },
        {
            text: this.$t("supervisor.freightCalculationForAccounting"),
            align: "start",
            value: "freightCalculationForAccounting",
            width: '300px',
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "username",
            sortable: false,
        },
        {
            text: this.$t("common.createdAt"),
            align: "start",
            value: "createdAt",
            sortable: false,
        },
        {
            text: this.$t("supervisor.customerFreight"),
            align: "start",
            value: "saleValueWithCurrency",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    incomeDocumentAttachmentListHeaders = [
        {
            text: this.$t("costDocument.name"),
            align: "start",
            value: "name",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get validationError(): any {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentValidationError;
    }

    set validationError(val: any) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentValidationError(val);
    }

    get incomeDocument(): SettlementIncomeDocumentRestModel {
        const incomeDocument = this.$store.direct.state.settlementIncomeDocument.incomeDocument;

        if (incomeDocument.contractor != undefined) {
            if (this.contractorDetailsForSettlement == undefined || Object.keys(this.contractorDetailsForSettlement).length === 0) {
                this.loadContractorDetailsForSettlement(incomeDocument.contractor.value);
            }

            this.contractorFound.push(incomeDocument.contractor);
        }

        if (incomeDocument.currencyRate != undefined) {
            this.currencyRate = incomeDocument.currencyRate;
        }

        if (incomeDocument.bankAccountId == 0 && incomeDocument.contractorName != undefined && this.contractorDetailsForSettlement.defaultIncomeDocumentBankAccountId != undefined) {
            incomeDocument.bankAccountId = this.contractorDetailsForSettlement.defaultIncomeDocumentBankAccountId;
        }

        incomeDocument.discount = incomeDocument.discount == undefined ? 0 : incomeDocument.discount;
        if (incomeDocument.discount != 0) {
            this.discountEnabled = true;
        }

        return incomeDocument;
    }

    set incomeDocument(val: SettlementIncomeDocumentRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument(val);
    }

    get documentRecalculatingType(): SettlementDocumentRecalculatingTypeEnum {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentDocumentRecalculatingType;
    }

    set documentRecalculatingType(val: SettlementDocumentRecalculatingTypeEnum) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentDocumentRecalculatingType(val);
    }

    get documentTypeChanged(): boolean {
        return this.incomeDocument.documentTypeId == 4 ? false : true;
    }

    get incomeDocumentIssueDate() {
        let issueDate = this.$store.direct.state.settlementIncomeDocument.incomeDocumentIssueDate;
        if (issueDate && issueDate != null && issueDate != undefined) {
            const formattedIssueDate = this.formatDateTime(issueDate);
            issueDate = formattedIssueDate != undefined ? formattedIssueDate : "";
        }

        return issueDate;
    }

    set incomeDocumentIssueDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentIssueDate(val);
    }

    get incomeDocumentPaymentDate() {
        let paymentDate = this.$store.direct.state.settlementIncomeDocument.incomeDocumentPaymentDate;
        if (paymentDate && paymentDate != null && paymentDate != undefined) {
            const formattedPaymentDate = this.formatDateTime(paymentDate);
            paymentDate = formattedPaymentDate != undefined ? formattedPaymentDate : "";
        }

        return paymentDate;
    }

    set incomeDocumentPaymentDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentPaymentDate(val);
    }

    get incomeDocumentSaleDate() {
        let saleDate = this.$store.direct.state.settlementIncomeDocument.incomeDocumentSaleDate;
        if (saleDate && saleDate != null && saleDate != undefined) {
            const formattedSaleDate = this.formatDateTime(saleDate);
            saleDate = formattedSaleDate != undefined ? formattedSaleDate : "";
        }

        return saleDate;
    }

    set incomeDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate(val);
    }

    get incomeDocumentItem(): SettlementIncomeDocumentItemRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentItem;
    }

    set incomeDocumentItem(val: SettlementIncomeDocumentItemRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItem(val);
    }

    get incomeDocumentItemList(): SettlementIncomeDocumentItemRestModel[] {
        let incomeDocumentItemList = this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemList;
        if (incomeDocumentItemList == undefined) {
            incomeDocumentItemList = [] as SettlementIncomeDocumentItemRestModel[];
        }

        if (!this.isIncomeDocumentCorrected) {
            incomeDocumentItemList.forEach(async (x: SettlementIncomeDocumentItemRestModel) => {
                if (this.currencyRate?.rate != undefined) {
                    x.pricePln = this.incomeDocument.currencyCode == this.plnCurrency?.code ? x.price : await this.$store.direct.dispatch.settlementCommon.roundValueToTwoPlaces(x.price * this.currencyRate?.rate);
                }
                await this.calculateNettoBruttoAndTaxValue(x);
                await this.calculateNettoBruttoAndTaxValueInPln(x);
            });
        }

        this.calculateItemsAmounts(incomeDocumentItemList);
        this.assignIncomeDocumentItemSummaryList(incomeDocumentItemList);

        return incomeDocumentItemList;
    }

    set incomeDocumentItemList(val: SettlementIncomeDocumentItemRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemList(val);
    }

    get incomeDocumentItemCorrectionList(): SettlementIncomeDocumentItemRestModel[] {
        let incomeDocumentItemCorrectionList = this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemCorrectionList;
        if (incomeDocumentItemCorrectionList == undefined) {
            incomeDocumentItemCorrectionList = [] as SettlementIncomeDocumentItemRestModel[];
        }

        if (this.isIncomeDocumentCorrected) {
            incomeDocumentItemCorrectionList.forEach(async (x: SettlementIncomeDocumentItemRestModel) => {
                if (this.currencyRate?.rate != undefined) {
                    x.pricePln = this.incomeDocument.currencyCode == this.plnCurrency?.code ? x.price : await this.$store.direct.dispatch.settlementCommon.roundValueToTwoPlaces(x.price * this.currencyRate?.rate);
                }

                await this.calculateNettoBruttoAndTaxValue(x);
                await this.calculateNettoBruttoAndTaxValueInPln(x);
            });

            this.assignIncomeDocumentItemCorrectionDifferenceList(incomeDocumentItemCorrectionList);
        }

        return incomeDocumentItemCorrectionList;
    }

    set incomeDocumentItemCorrectionList(val: SettlementIncomeDocumentItemRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemCorrectionList(val);
    }

    get incomeDocumentSupervisorList(): SupervisorForSettlementRestModel[] {
        let incomeDocumentSupervisorList = this.$store.direct.state.settlementCommon.supervisorList;

        this.calculateIncomeDocumentItemPriceAfterAddedNewSupervisor(incomeDocumentSupervisorList);

        if (incomeDocumentSupervisorList == undefined) {
            incomeDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];
        }
        else {
            incomeDocumentSupervisorList.sort((n1, n2) => {
                if (n1.orderId > n2.orderId) {
                    return 1;
                }
                if (n1.orderId < n2.orderId) {
                    return -1;
                }
                return 0;
            });
        }

        return incomeDocumentSupervisorList;
    }

    set incomeDocumentSupervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get incomeDocumentAttachmentList(): SettlementIncomeDocumentAttachmentRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.attachmentList;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    set contractorFound(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.common.setContractorSearch(val);
    }

    get contractorDetailsForSettlement(): ContractorDetailsForSettlementRestModel {
        const contractorDetailsForSettlement = this.$store.direct.state.contractor.contractorDetailsForSettlement;
        this.showAccountingInfoDialog(contractorDetailsForSettlement);

        return contractorDetailsForSettlement;
    }

    set contractorDetailsForSettlement(val: ContractorDetailsForSettlementRestModel) {
        this.$store.direct.commit.contractor.setContractorDetailsForSettlement(val);
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get languageList(): CountryRestModel[] {
        return this.countryList?.filter((x: CountryRestModel) => {
            return x.code == "PL" || x.code == "EN" || x.code == "DE";
        });
    }

    get paymentStatuses(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentStatuses;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        const paymentTypes = this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
        const mappedpaymentTypes = [] as ListItemNumberModel[];

        if (paymentTypes != undefined) {
            paymentTypes.forEach((x: ListItemNumberModel) => {
                if (x.value != 3) {
                    mappedpaymentTypes.push({ text: x.text, value: x.value, disabled: false } as any);
                }
                else {
                    mappedpaymentTypes.push({ text: x.text, value: x.value, disabled: true } as any);
                }
            })
        }

        return mappedpaymentTypes;
    }

    get publicCorrectionReasonList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.publicCorrectionReasons;
    }

    get marwayBankAccounts(): ContractorBankAccountRestModel[] {
        const marwayDeBankAccounts = this.$store.direct.state.settlementCommon.settlementAssets.marwayDeBankAccounts;
        const marwayPlBankAccounts = this.$store.direct.state.settlementCommon.settlementAssets.marwayPlBankAccounts;
        const marwayTrBankAccounts = this.$store.direct.state.settlementCommon.settlementAssets.marwayTrBankAccounts;

        if (marwayDeBankAccounts != undefined && marwayPlBankAccounts != undefined && marwayTrBankAccounts != undefined) {
            let bankAccountList = [] as ContractorBankAccountRestModel[];
            if (this.incomeDocument.branchId == BranchEnum.DE) {
                bankAccountList = marwayDeBankAccounts;
            }
            else if (this.incomeDocument.branchId == BranchEnum.PL) {
                bankAccountList = marwayPlBankAccounts;
            }
            else if (this.incomeDocument.branchId == BranchEnum.TR) {
                bankAccountList = marwayTrBankAccounts;
            }
            else {
                bankAccountList = marwayDeBankAccounts.concat(marwayPlBankAccounts).concat(marwayTrBankAccounts);
            }
            return bankAccountList.filter((x: ContractorBankAccountRestModel) => {
                return x.bankAccountPurposeTypeId == 0 || x.bankAccountPurposeTypeId == 2;
            });
        }
        return [] as ContractorBankAccountRestModel[];
    }

    get marwayVatBankAccounts(): ContractorBankAccountRestModel[] {

        if (this.marwayBankAccounts != undefined) {
            return this.marwayBankAccounts.filter((x: ContractorBankAccountRestModel) => {
                return x.isVatAccount == true;
            });
        }
        return [] as ContractorBankAccountRestModel[];
    }

    get incomeDocumentTypes(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentTypes;
    }

    get documentTypes(): SettlementDocumentTypeRestModel[] {
        const documentTypes = this.$store.direct.state.settlementCommon.settlementAssets.documentTypes;
        const mappedDocumentTypes = [] as SettlementDocumentTypeRestModel[];

        if (documentTypes != undefined) {
            documentTypes.forEach((x: SettlementDocumentTypeRestModel) => {
                if (x.id == 1 || x.id == 4 || x.id == 6) {
                    mappedDocumentTypes.push({ id: x.id, code: x.code, name: x.name, disabled: false } as any);
                }
                else {
                    mappedDocumentTypes.push({ id: x.id, code: x.code, name: x.name, disabled: true } as any);
                }
            })
        }

        return mappedDocumentTypes;
    }

    get incomeDocumentInvoiceTypes(): IncomeDocumentInvoiceTypeModel[] {
        const incomeDocumentInvoiceTypes = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentInvoiceTypes;
        const mappedIncomeDocumentInvoiceTypes = [] as IncomeDocumentInvoiceTypeModel[];

        if (incomeDocumentInvoiceTypes != undefined) {
            incomeDocumentInvoiceTypes.forEach((x: ListItemNumberModel) => {
                if (x.value == 1 || x.value == 4) {
                    mappedIncomeDocumentInvoiceTypes.push({ text: x.text, value: x.value, disabled: false });
                }
                else {
                    mappedIncomeDocumentInvoiceTypes.push({ text: x.text, value: x.value, disabled: true });
                }
            })
        }
        return mappedIncomeDocumentInvoiceTypes;
    }

    get incomeDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentStatuses;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;

        if (currencies != undefined) {
            this.plnCurrency = currencies.find((x: CurrencyRestModel) => {
                return x.code == "PLN"
            });
        }
        return currencies;
    }

    get currencyRate(): CurrencyRateRestModel {
        const currencyRate = this.$store.direct.state.settlementCommon.currencyRate;
        if (currencyRate.rateDate != undefined) {
            const formattedDate = this.formatDateTime(currencyRate.rateDate);
            currencyRate.rateDate = formattedDate != undefined ? formattedDate : "";
        }
        return currencyRate;
    }

    set currencyRate(val: CurrencyRateRestModel) {
        this.$store.direct.commit.settlementCommon.setCurrencyRate(val);
    }

    get branchList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.contractorAssets.branches;
    }

    get incomeDocumentSaveResponse(): SettlementDocumentSaveResponseRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentSaveResponse;
    }

    get previousUrlName(): string | undefined | null {
        return this.$store.direct.state.settlementCommon.previousUrlName;
    }

    get incomeDocumentMementoList(): SettlementIncomeDocumentMementoItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentMementoList;
    }

    get incomeDocumentMementoListCount(): number {
        const incomeDocumentMementoListCount = this.$store.direct.state.settlementIncomeDocument.incomeDocumentMementoListCount - 1;
        this.lastIncomeDocumentMementoPoint = incomeDocumentMementoListCount;
        this.incomeDocumentMementoSelectedPoint = incomeDocumentMementoListCount;
        return incomeDocumentMementoListCount;
    }

    get isIncomeDocumentInHistoricalState(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.isIncomeDocumentInHistoricalState;
    }

    set isIncomeDocumentInHistoricalState(val: boolean) {
        this.$store.direct.commit.settlementIncomeDocument.setIsIncomeDocumentInHistoricalState(val);
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    get userIsAdminOrAccountantAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin);
    }

    get documentIsPaidAndUserIsNotAdminOrAccountantAdmin(): boolean {
        const result = !this.userIsAdminOrAccountantAdmin && this.incomeDocument.paymentStatusId != SettlementPaymentStatusEnum.Niezaplacona
        return result;
    }

    @Watch("incomeDocument", { deep: true })
    async incomeDocumentHandler(val: SettlementIncomeDocumentRestModel) {
        await this.setIncomeDocumentDocumentRecalculatingTypeByBranch();
        this.marwayBankAccounts;
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        if (this.contractorSearch) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        }
    }

    @Watch("incomeDocumentSaleDate")
    async incomeDocumentSaleDateHandler(val: string) {
        await this.getCurrencyRate();
    }

    @Watch("incomeDocumentIssueDate")
    async incomeDocumentIssueDateHandler(val: string) {
        await this.getCurrencyRate();
    }

    @Watch("discountEnabled")
    async discountEnabledHandler(val: boolean) {
        if (this.discountEnabled == false) {
            this.incomeDocument.discount = 0;
            await this.discountChanged();
        }
    }

    @Watch("documentRecalculatingType")
    async documentRecalculatingTypeHandler(val: number) {
        if (val == 1) {
            this.incomeDocument.isVatInPln = true;
        } else {
            this.incomeDocument.isVatInPln = false;
        }
    }

    @Watch("validationError.IncomeDocumentItems")
    async validationErrorIncomeDocumentItemsHandler(val: any) {
        if (this.validationError.IncomeDocumentItems == undefined) {
            this.incomeDocumentItemsLengthError = null;
        }
        else {
            this.incomeDocumentItemsLengthError = this.validationError.IncomeDocumentItems[0];
            setTimeout(() => {
                this.incomeDocumentItemsLengthError = null;
            }, 3000)
        }
    }

    @Watch("validationError.Description")
    async validationErrorIncomeDocumentItemDescriptionHandler(val: any) {
        if (this.validationError.Description == undefined) {
            this.incomeDocumentItemDescriptionError = null;
        }
        else {
            this.incomeDocumentItemDescriptionError = this.validationError.Description[0];
            setTimeout(() => {
                this.incomeDocumentItemDescriptionError = null;
            }, 3000)
        }
    }

    @Watch("validationError.Price")
    async validationErrorIncomeDocumentItemPriceHandler(val: any) {
        if (this.validationError.Price == undefined) {
            this.incomeDocumentItemPriceError = null;
        }
        else {
            this.incomeDocumentItemPriceError = this.validationError.Price[0];
            setTimeout(() => {
                this.incomeDocumentItemPriceError = null;
            }, 3000)
        }
    }

    @Watch("validationError.TaxTypeId")
    async validationErrorIncomeDocumentItemTaxTypeIdHandler(val: any) {
        if (this.validationError.TaxTypeId == undefined) {
            this.incomeDocumentItemTaxTypeIdError = null;
        }
        else {
            this.incomeDocumentItemTaxTypeIdError = this.validationError.TaxTypeId[0];
            setTimeout(() => {
                this.incomeDocumentItemTaxTypeIdError = null;
            }, 3000)
        }
    }

    async created() {
        if (this.$route.name == "Create income document") {
            this.isIncomeDocumentNew = true;
            this.isIncomeDocumentCorrected = false;
            this.incomeDocument.isDocumentCorrection = false;
            this.toolbarTitle = this.$t("incomeDocument.addIncomeDocument").toString();
        }
        if (this.$route.name == "Edit income document") {
            this.isIncomeDocumentNew = false;
            this.isIncomeDocumentCorrected = false;
            this.incomeDocument.isDocumentCorrection = false;
            this.toolbarTitle = this.$t("incomeDocument.editIncomeDocument").toString();
        }
        if (this.$route.name == "Create income document correction") {
            this.isIncomeDocumentCorrected = true;
            this.isIncomeDocumentCorrectionNew = true;
            this.incomeDocument.isDocumentCorrection = true;
            this.toolbarTitle = this.$t("incomeDocument.addIncomeDocumentCorrection").toString() + " - do: " + this.incomeDocument.correctedDocumentNumber;
        }
        if (this.$route.name == "Edit income document correction") {
            this.isIncomeDocumentCorrected = true;
            this.isIncomeDocumentCorrectionNew = false;
            this.incomeDocument.isDocumentCorrection = true;
            this.toolbarTitle = this.$t("incomeDocument.editIncomeDocumentCorrection").toString() + " - do: " + this.incomeDocument.correctedDocumentNumber;
        }

        await this.$store.direct.dispatch.contractor.loadContractorAssets();
        await this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocumentAttachmentList();

        if (this.$route.params.id == undefined) {
            this.incomeDocument.incomeDocumentStatusId = 1;
            this.incomeDocument.documentTypeId = 1;
            this.incomeDocument.incomeDocumentInvoiceTypeId = 1;
            this.incomeDocument.paymentStatusId = SettlementPaymentStatusEnum.Niezaplacona;
            this.incomeDocument.paymentTypeId = SettlementPaymentTypeEnum.PRZELEW;
            this.incomeDocumentIssueDate = dayjs().format("YYYY-MM-DD");
            this.isIncomeDocumentNew = true;

            if (this.incomeDocumentSupervisorList.length == 0) {
                this.incomeDocument.currencyCode = "EUR";
            }
        }
        else {
            this.documentRecalculatingType = this.incomeDocument.documentRecalculatingType;
        }

        this.setIncomeDocumentDocumentRecalculatingTypeByBranch();
    }

    async loadContractorDetailsForSettlement(contractorId: number) {
        await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: contractorId });
    }

    async showAccountingInfoDialog(contractorDetailsForSettlement: ContractorDetailsForSettlementRestModel) {
        if (contractorDetailsForSettlement.accountingInfo != undefined && contractorDetailsForSettlement.accountingInfo != null) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("contractor.accountingInfo")}`,
                    message: contractorDetailsForSettlement.accountingInfo,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "red" },
                    buttonType: "ok"
                });
        }
    }

    contractorCountryChanged() {
        if (this.incomeDocument.incomeDocumentTypeId == undefined && this.incomeDocument.branchId != undefined && this.incomeDocument.contractorCountry != undefined) {
            const isContractorCountryInUE = this.countryList.find((x: CountryRestModel) => {
                return x.code == this.incomeDocument.contractorCountry;
            })?.isInUE;

            if (isContractorCountryInUE != undefined) {
                if (this.incomeDocument.branchId == BranchEnum.DE && this.incomeDocument.contractorCountry == "DE") {
                    this.incomeDocument.incomeDocumentTypeId = 1; // KR - krajowa
                }
                else if (this.incomeDocument.branchId == BranchEnum.PL && this.incomeDocument.contractorCountry == "PL") {
                    this.incomeDocument.incomeDocumentTypeId = 1; // KR - krajowa
                }
                else if (isContractorCountryInUE) {
                    this.incomeDocument.incomeDocumentTypeId = 2; // UE - unijna
                }
                else {
                    this.incomeDocument.incomeDocumentTypeId = 3; // MN - międzynarodowa
                }
            }
        }
    }

    setIncomeDocumentDocumentRecalculatingTypeByBranch() {
        if (this.incomeDocument.branchId == BranchEnum.DE) {
            this.incomeDocument.isVatInPln = false;
            this.documentRecalculatingType = SettlementDocumentRecalculatingTypeEnum.DocumentInForeignCurrency;
        }
    }

    selectedBranchIdChanged() {
        if (this.incomeDocument.branchId == BranchEnum.DE) {
            this.incomeDocument.documentLanguage = "DE";
        }
        else {
            this.incomeDocument.documentLanguage = "PL";
        }
        this.incomeDocument.bankAccountId = 0;

        this.incomeDocumentItemList.forEach(x => {
            x.description = undefined as any;
            x.serviceTypeId = 0;
            x.taxTypeId = 0;
        })
    }

    async getCurrencyRate() {
        if (this.incomeDocument.currencyCode != undefined
            && this.incomeDocumentSaleDate
            && this.incomeDocumentIssueDate
            && this.incomeDocument.currencyCode != this.plnCurrency?.code) {
            const date = dayjs(this.incomeDocumentSaleDate) < dayjs(this.incomeDocumentIssueDate) ? this.incomeDocumentSaleDate : this.incomeDocumentIssueDate;
            await this.$store.direct.dispatch.settlementCommon.getCurrencyRate({ currencyCode: this.incomeDocument.currencyCode, date: date });
        }
    }

    async currencyCodeChanged(currencyCode: string) {
        if (this.plnCurrency != undefined && currencyCode == this.plnCurrency.code) {
            this.currencyRate = { rate: undefined, rateDate: undefined, id: 0 };

            this.documentRecalculatingType = 1;
            this.incomeDocument.isVatInPln = true;
        }
        await this.getCurrencyRate();
    }

    assignIncomeDocumentItemCorrectionDifferenceList(incomeDocumentItemCorrectionList: SettlementIncomeDocumentItemRestModel[]) {
        const priceBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.price, 0);
        const pricePlnBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.pricePln, 0);
        const nettoBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.netto, 0);
        const nettoPlnBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.nettoPln, 0);
        const bruttoBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.brutto, 0);
        const bruttoPlnBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.bruttoPln, 0);
        const taxBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.tax, 0);
        const taxPlnBefore = this.incomeDocumentItemList.reduce((sum, current) => sum + current.taxPln, 0);

        const priceAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.price, 0);
        const pricePlnAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.pricePln, 0);
        const nettoAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.netto, 0);
        const nettoPlnAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.nettoPln, 0);
        const bruttoAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.brutto, 0);
        const bruttoPlnAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.bruttoPln, 0);
        const taxAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.tax, 0);
        const taxPlnAfter = incomeDocumentItemCorrectionList.reduce((sum, current) => sum + current.taxPln, 0);

        const item = {
            price: this.roundValueToTwoPlaces(priceAfter - priceBefore),
            pricePln: this.roundValueToTwoPlaces(pricePlnAfter - pricePlnBefore),
            netto: this.roundValueToTwoPlaces(nettoAfter - nettoBefore),
            nettoPln: this.roundValueToTwoPlaces(nettoPlnAfter - nettoPlnBefore),
            brutto: this.roundValueToTwoPlaces(bruttoAfter - bruttoBefore),
            bruttoPln: this.roundValueToTwoPlaces(bruttoPlnAfter - bruttoPlnBefore),
            tax: this.roundValueToTwoPlaces(taxAfter - taxBefore),
            taxPln: this.roundValueToTwoPlaces(taxPlnAfter - taxPlnBefore),

        } as SettlementIncomeDocumentItemRestModel;

        this.incomeDocumentItemCorrectionDifferenceList = [item];
    }

    async assignIncomeDocumentItemSummaryList(incomeDocumentItemList: SettlementIncomeDocumentItemRestModel[]) {
        let description = "";
        let unit = "";
        if (incomeDocumentItemList.length > 0) {
            description = incomeDocumentItemList.reduce(
                function (a, b) {
                    return a.description.length > b.description.length ? a : b;
                }
            ).description;

            unit = incomeDocumentItemList.reduce(
                function (a, b) {
                    return a.unit.length > b.unit.length ? a : b;
                }
            ).unit;
        }

        const quantity = incomeDocumentItemList.reduce((sum, current) => +sum + +current.quantity, 0);
        const price = incomeDocumentItemList.reduce((sum, current) => sum + current.price, 0);
        const pricePln = incomeDocumentItemList.reduce((sum, current) => sum + current.pricePln, 0);
        const netto = incomeDocumentItemList.reduce((sum, current) => sum + current.netto, 0);
        const nettoPln = incomeDocumentItemList.reduce((sum, current) => sum + current.nettoPln, 0);
        const brutto = incomeDocumentItemList.reduce((sum, current) => sum + current.brutto, 0);
        const bruttoPln = incomeDocumentItemList.reduce((sum, current) => sum + current.bruttoPln, 0);
        const tax = incomeDocumentItemList.reduce((sum, current) => sum + current.tax, 0);
        const taxPln = incomeDocumentItemList.reduce((sum, current) => sum + current.taxPln, 0);

        const item = {
            description: description,
            descriptionDetailed: "",
            quantity: quantity,
            unit: unit,
            price: this.roundValueToTwoPlaces(price),
            pricePln: this.roundValueToTwoPlaces(pricePln),
            netto: this.roundValueToTwoPlaces(netto),
            nettoPln: this.roundValueToTwoPlaces(nettoPln),
            brutto: this.roundValueToTwoPlaces(brutto),
            bruttoPln: this.roundValueToTwoPlaces(bruttoPln),
            tax: this.roundValueToTwoPlaces(tax),
            taxPln: this.roundValueToTwoPlaces(taxPln),
        } as SettlementIncomeDocumentItemRestModel;

        this.incomeDocumentItemSummaryList = [item];
    }

    roundValueToTwoPlaces(value: number) {
        return Math.round(value * 100) / 100;
    }

    async discountChanged() {
        this.incomeDocument.discount = this.changeCommaToDot(this.incomeDocument.discount);
        await this.calculateItemsAmounts(this.incomeDocumentItemList);
    }

    async calculateItemsAmounts(incomeDocumentItemList: SettlementIncomeDocumentItemRestModel[]) {
        if (!this.isIncomeDocumentCorrected) {
            await incomeDocumentItemList.forEach(async (x: SettlementIncomeDocumentItemRestModel) => {
                await this.calculateNettoBruttoAndTaxValue(x);
                await this.calculateNettoBruttoAndTaxValueInPln(x);
            });
            this.$forceUpdate();
        }
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    changeCommaToDot(val: any): number {
        return parseFloat(val.toString().replace(",", "."));
    }

    async calculateNettoBruttoAndTaxValue(item: SettlementIncomeDocumentItemRestModel) {
        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValue({ item: item, parentComponentName: "income-document" });
        await this.$forceUpdate();
    }

    async calculateNettoBruttoAndTaxValueInPln(item: SettlementIncomeDocumentItemRestModel) {
        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValueInPln({ item: item, parentComponentName: "income-document" });
        await this.$forceUpdate();
    }

    async selectedContractorChanged(incomeDocument: SettlementIncomeDocumentRestModel | undefined = undefined) {
        if (incomeDocument == undefined) {
            incomeDocument = this.incomeDocument;
        }

        if (incomeDocument.contractor != null) {
            const contractorId = incomeDocument.contractor.value;
            await this.loadContractorDetailsForSettlement(contractorId);

            incomeDocument.contractorName = this.contractorDetailsForSettlement.name;
            incomeDocument.contractorSymbol = this.contractorDetailsForSettlement.symbol;
            incomeDocument.contractorCountry = this.contractorDetailsForSettlement.country;
            incomeDocument.contractorCity = this.contractorDetailsForSettlement.city;
            incomeDocument.contractorPostalCode = this.contractorDetailsForSettlement.postalCode;
            incomeDocument.contractorStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
            incomeDocument.contractorTin = this.contractorDetailsForSettlement.tin;
            incomeDocument.contractorTinPrefix = this.contractorDetailsForSettlement.tinPrefix;
            incomeDocument.contractorIsPrivatePerson = this.contractorDetailsForSettlement.isPrivatePerson

            if (this.contractorDetailsForSettlement.defaultLangForIncomeDocuments != null && this.contractorDetailsForSettlement.defaultLangForIncomeDocuments != undefined) {
                incomeDocument.documentLanguage = this.contractorDetailsForSettlement.defaultLangForIncomeDocuments;
            }
        }
        this.$forceUpdate();

        this.contractorCountryChanged();
    }

    formatDateTime(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    async addIncomeDocumentItem() {
        this.incomeDocumentItem = {
            description: "", quantity: 1, unit: "SZT."
        } as SettlementIncomeDocumentItemRestModel;
        await this.$store.direct.dispatch.settlementIncomeDocument
            .openIncomeDocumentItemDialog({
                title: `${this.$t("incomeDocument.item")}`,
                isFromCorrection: false,
            })
    }

    async editIncomeDocumentItem(item: SettlementIncomeDocumentItemRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItem(item);
        await this.$store.direct.dispatch.settlementIncomeDocument
            .openIncomeDocumentItemDialog({
                title: `${this.$t("incomeDocument.item")}`,
                isFromCorrection: false,
            })
    }

    deleteIncomeDocumentItem(item: SettlementIncomeDocumentItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("incomeDocument.removeIncomeDocumentItemTitle")}`,
                message: `${this.$t("incomeDocument.removeIncomeDocumentItemMessage")}` + ": " + item.description,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundItem = this.incomeDocumentItemList.find(x => {
                        return x.price == item.price && x.taxRate == item.taxRate;
                    });
                    if (foundItem != undefined) {
                        const index = this.incomeDocumentItemList.indexOf(foundItem);
                        if (index != undefined && index > -1) {
                            this.incomeDocumentItemList.splice(index, 1);
                        }
                    }
                }
            })
    }

    async addIncomeDocumentItemCorrection() {
        this.incomeDocumentItem = {
            description: "", quantity: 1, unit: "SZT."
        } as SettlementIncomeDocumentItemRestModel;
        await this.$store.direct.dispatch.settlementIncomeDocument
            .openIncomeDocumentItemDialog({
                title: `${this.$t("incomeDocument.item")}`,
                isFromCorrection: true,
            })
    }

    async editIncomeDocumentItemCorrection(item: SettlementIncomeDocumentItemRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItem(item);
        await this.$store.direct.dispatch.settlementIncomeDocument
            .openIncomeDocumentItemDialog({
                title: `${this.$t("incomeDocument.item")}`,
                isFromCorrection: true,
            })
    }

    deleteIncomeDocumentItemCorrection(item: SettlementIncomeDocumentItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("incomeDocument.removeIncomeDocumentItemTitle")}`,
                message: `${this.$t("incomeDocument.removeIncomeDocumentItemMessage")}` + ": " + item.description,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundItem = this.incomeDocumentItemCorrectionList.find(x => {
                        return x.description == item.description;
                    });
                    if (foundItem != undefined) {
                        const index = this.incomeDocumentItemCorrectionList.indexOf(foundItem);
                        if (index != undefined && index > -1) {
                            this.incomeDocumentItemCorrectionList.splice(index, 1);
                        }
                    }
                }
            })
    }

    calculateIncomeDocumentItemPriceAfterAddedNewSupervisor(incomeDocumentSupervisorList: SupervisorForSettlementRestModel[]) {
        let allAssignedSupervisorsHasTheSameCurrency = true;
        incomeDocumentSupervisorList.forEach((x: SupervisorForSettlementRestModel) => {
            if (x.saleValueCurrency != this.incomeDocument.currencyCode) {
                allAssignedSupervisorsHasTheSameCurrency = false;
            }
        });

        if (incomeDocumentSupervisorList.length > 1 && this.assignSupervisorWasClicked && this.incomeDocumentItemList.length == 1 && allAssignedSupervisorsHasTheSameCurrency) {
            const totalPriceOfNewAssignedSupervisors = incomeDocumentSupervisorList.filter((x: SupervisorForSettlementRestModel) => {
                if (!this.previouslyAssignedSupervisorIdcs.includes(x.id) && x.saleValue != undefined) {
                    return x.saleValue;
                }
            }).reduce((sum, current) => sum + current.saleValue, 0);
            this.incomeDocumentItemList[0].price = this.incomeDocumentItemList[0].price + totalPriceOfNewAssignedSupervisors;
        }
    }

    async assignIncomeDocumentSupervisor() {
        this.previouslyAssignedSupervisorIdcs = this.incomeDocumentSupervisorList.map((x: SupervisorForSettlementRestModel) => {
            return x.id;
        });

        await this.$store.direct.dispatch.settlementCommon
            .openAssignSupervisorDialog({
                title: `${this.$t("incomeDocument.assignIncomeDocumentSupervisor")}`
            }).then(async confirm => {
                if (await confirm) {
                    this.assignSupervisorWasClicked = true;
                    this.incomeDocumentSupervisorList;
                }
                this.assignSupervisorWasClicked = false;
                this.previouslyAssignedSupervisorIdcs = [] as number[];
            });
    }

    showIncomeDocumentSupervisor(item: SupervisorRestModel) {
        return "/supervisor/edit/" + item.id;
    }

    deleteIncomeDocumentSupervisor(item: SupervisorRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("incomeDocument.removeSupervisorAssignmentTitle")}`,
                message: `${this.$t("incomeDocument.removeSupervisorAssignmentMessage")}` + item.orderId,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundSupervisor = this.incomeDocumentSupervisorList.find(x => {
                        return x.id == item.id;
                    });
                    if (foundSupervisor != undefined) {
                        const index = this.incomeDocumentSupervisorList.indexOf(foundSupervisor);
                        if (index != undefined && index > -1) {
                            this.incomeDocumentSupervisorList.splice(index, 1);
                        }
                    }
                }
            })
    }

    async attachmentCreateClick() {
        await this.$store.direct.dispatch.settlementIncomeDocument.openAttachmentDialog()
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementIncomeDocument.saveAttachments();
                }
            });
    }

    async downloadIncomeDocumentAttachment(incomeDocumentAttachment: SettlementIncomeDocumentAttachmentRestModel) {
        await this.$store.direct.dispatch.settlementIncomeDocument.downloadIncomeDocumentAttachment({ incomeDocumentId: this.incomeDocument.id, incomeDocumentAttachmentId: incomeDocumentAttachment.id });
    }

    cancelClick() {
        this.backToList();
    }

    async copyIncomeDocumentClick() {
        const previousIncomeDocumentId = this.incomeDocument.id;
        await this.$router.push({ path: "/settlement/income-document/create" });

        const temporaryIncomeDocument = this.$store.direct.state.settlementIncomeDocument.incomeDocument;
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: previousIncomeDocumentId });

        this.incomeDocument.id = temporaryIncomeDocument.id;
        this.incomeDocument.concurrencyStamp = temporaryIncomeDocument.concurrencyStamp;

        this.incomeDocument.hasCorrection = false;
        this.incomeDocument.documentNumber = undefined as any;
        this.incomeDocument.paymentStatusId = SettlementPaymentStatusEnum.Niezaplacona;

        this.incomeDocument.issueDate = undefined as any;
        this.incomeDocumentIssueDate = undefined as any;

        this.incomeDocument.saleDate = undefined as any;
        this.incomeDocumentSaleDate = undefined as any;

        this.incomeDocument.paymentDate = undefined as any;
        this.incomeDocumentPaymentDate = undefined as any;

        this.incomeDocument.disableEditingDueToClosedMonth = false;

        this.incomeDocument.incomeDocumentItems.forEach((x: SettlementIncomeDocumentItemRestModel) => {
            x.id = 0;
        });

        this.incomeDocument.incomeDocumentSupervisors = [] as SupervisorForSettlementRestModel[];
        this.incomeDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];
    }

    async saveClick(pushRouteToIncomeDocumentList: boolean = true): Promise<boolean> {
        if (this.documentRecalculatingType != SettlementDocumentRecalculatingTypeEnum.DocumentInForeignCurrency || this.incomeDocument.isVatInPln == false) {
            this.incomeDocument.bankAccountVatId = undefined as any;
        }

        if (this.isIncomeDocumentCorrected) {
            this.incomeDocument.incomeDocumentItems = this.incomeDocumentItemCorrectionList;
            this.incomeDocument.isDocumentCorrection = true;
        }
        else {
            this.incomeDocument.incomeDocumentItems = this.incomeDocumentItemList;
            this.incomeDocument.isDocumentCorrection = false;
        }

        this.incomeDocument.incomeDocumentSupervisors = this.incomeDocumentSupervisorList;
        this.incomeDocument.currencyRate = this.currencyRate;

        this.incomeDocument.issueDate = this.incomeDocumentIssueDate === "" ? undefined as any : this.incomeDocumentIssueDate;
        this.incomeDocument.saleDate = this.incomeDocumentSaleDate === "" ? undefined as any : this.incomeDocumentSaleDate;
        this.incomeDocument.paymentDate = this.incomeDocumentPaymentDate === "" ? undefined as any : this.incomeDocumentPaymentDate;
        this.incomeDocument.paymentTerm = (this.incomeDocument.paymentTerm == null || this.incomeDocument.paymentTerm.toString().length == 0) ? undefined as any : this.incomeDocument.paymentTerm;
        this.incomeDocument.discount = this.incomeDocument.discount.toString().length == 0 ? 0 : this.incomeDocument.discount;
        this.incomeDocument.documentRecalculatingType = this.documentRecalculatingType;

        this.incomeDocument.incomeDocumentItems?.forEach((x: SettlementIncomeDocumentItemRestModel) => {
            x.price = x.price.toString().length == 0 ? 0 : x.price;
            x.taxRate = x.taxRate.toString().length == 0 ? 0 : x.taxRate;
        });

        if (this.isIncomeDocumentCorrected) {
            await this.$store.direct.dispatch.settlementIncomeDocument.saveIncomeDocumentCorrection();
        }
        else {
            await this.$store.direct.dispatch.settlementIncomeDocument.saveIncomeDocument();
        }

        if (this.$refs.form != undefined) {
            const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        }

        if (Object.keys(this.validationError).length === 0 && pushRouteToIncomeDocumentList == true && this.incomeDocumentSaveResponse.isSaved == true) {
            this.incomeDocument = {} as SettlementIncomeDocumentRestModel;
            this.backToList();
        }
        else if (Object.keys(this.validationError).length === 0) {
            return true;
        }
        return false;
    }

    async deleteIncomeDocumentFile(item: SettlementIncomeDocumentAttachmentRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("costDocument.deleteFileTitle").toString(),
                message: this.$t("costDocument.deletetFileMessage").toString() + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementIncomeDocument.deleteIncomeDocumentFile({ fileId: item.id });
                    await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocumentAttachmentList();
                }
            })
    }

    async generateAndGetIncomeDocumentFile() {
        await this.loadIncomeDocumentGenerateAndGetIncomeDocumentFileAndBackToList();
    }

    async saveIncomeDocumentAndGenerateAndGetIncomeDocumentFile() {
        const hasIncomeDocumentBeenSaved = await this.saveClick(false);

        if (hasIncomeDocumentBeenSaved) {
            await this.loadIncomeDocumentGenerateAndGetIncomeDocumentFileAndBackToList();
        }
    }

    async loadIncomeDocumentGenerateAndGetIncomeDocumentFileAndBackToList() {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: this.incomeDocument.id })

        if (this.incomeDocument.isDocumentCorrection) {
            await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentCorrectionFile({ incomeDocumentCorrectionId: this.incomeDocument.id });

            if ((this.incomeDocument.branchId == BranchEnum.DE && this.incomeDocument.documentLanguage != "DE") || (this.incomeDocument.branchId == BranchEnum.PL && this.incomeDocument.documentLanguage != "PL")) {
                await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentCorrectionFile({ incomeDocumentCorrectionId: this.incomeDocument.id, documentLanguage: this.incomeDocument.documentLanguage });
            }
        }
        else {
            await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentFile({ incomeDocumentId: this.incomeDocument.id });

            if ((this.incomeDocument.branchId == BranchEnum.DE && this.incomeDocument.documentLanguage != "DE") || (this.incomeDocument.branchId == BranchEnum.PL && this.incomeDocument.documentLanguage != "PL")) {
                await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentFile({ incomeDocumentId: this.incomeDocument.id, documentLanguage: this.incomeDocument.documentLanguage });
            }
        }

        this.backToList();
    }


    async generateAndGetIncomeDocumentXRechnungFile() {
        await this.loadIncomeDocumentGenerateAndGetIncomeDocumentXRechnungFileAndBackToList();
    }

    async saveIncomeDocumentAndGenerateAndGetIncomeDocumentXRechnungFile() {
        const hasIncomeDocumentBeenSaved = await this.saveClick(false);

        if (hasIncomeDocumentBeenSaved) {
            await this.loadIncomeDocumentGenerateAndGetIncomeDocumentXRechnungFileAndBackToList();
        }
    }

    async loadIncomeDocumentGenerateAndGetIncomeDocumentXRechnungFileAndBackToList() {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: this.incomeDocument.id })

        if (this.incomeDocument.isDocumentCorrection) {
            console.log("Dokument jest korektą. Nie zostało oprogramowane tworzenie plików XRechnung dla korekt.")
        }
        else {
            await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentXRechnungFile({ incomeDocumentId: this.incomeDocument.id });
        }

        this.backToList();
    }

    getIncomeDocumentMementoItem(value: number): SettlementIncomeDocumentMementoItemRestModel {
        if (value > -1 && this.incomeDocumentMementoList.length > 0) {
            return this.incomeDocumentMementoList[value];
        }
        return {} as SettlementIncomeDocumentMementoItemRestModel;
    }

    async incomeDocumentMementoPointChanged(historyPoint: number) {
        if (historyPoint != this.lastIncomeDocumentMementoPoint) {
            this.setIncomeDocumentItemsAndSupervisorsEmpty();

            if (historyPoint >= 0) {
                this.isIncomeDocumentInHistoricalState = true;
                await this.$store.direct.dispatch.settlementIncomeDocument.getIncomeDocumentMementoPoint({ mementoPoint: historyPoint });
                await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocumentAttachmentList({ mementoPoint: historyPoint });
            }
            this.lastIncomeDocumentMementoPoint = historyPoint;
        }
    }

    setIncomeDocumentItemsAndSupervisorsEmpty() {
        this.incomeDocument.incomeDocumentItems = [] as SettlementIncomeDocumentItemRestModel[];
        this.incomeDocument.incomeDocumentSupervisors = [] as SupervisorForSettlementRestModel[];
        this.incomeDocumentItemList = [] as SettlementIncomeDocumentItemRestModel[];
        this.incomeDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];
    }

    saveItemsOrder(event: any) {
        const movedItem = this.incomeDocumentItemList.splice(event.oldIndex, 1)[0];
        this.incomeDocumentItemList.splice(event.newIndex, 0, movedItem);

        let numberInSortingOrder = 0;
        this.incomeDocumentItemList.forEach((x => {
            x.numberInSortingOrder = numberInSortingOrder;
            numberInSortingOrder += 1;
        }))
    }

    destroyed() {
        this.$store.direct.dispatch.settlementIncomeDocument.cleanIncomeDocumentProperties();
        this.contractorFound = [] as ContractorSearchItemModel[];
        this.currencyRate = {} as CurrencyRateRestModel;
        this.validationError = {} as any;
        this.isIncomeDocumentInHistoricalState = false;
        this.contractorDetailsForSettlement = {} as ContractorDetailsForSettlementRestModel;
    }

    backToList() {
        if (this.isIncomeDocumentCorrected && this.previousUrlName != "Income payment obligation list") {
            router.push({ path: "/settlement/income-document/correction/list" });
        }
        else {
            if (this.previousUrlName != null && this.previousUrlName != undefined) {
                if (this.previousUrlName == "Edit income document" && this.$route.name == "Create income document") {
                    router.push({ path: "/settlement/income-document/list" });
                }
                else {
                    router.push({ name: this.previousUrlName });
                }
            }
            else {
                router.push({ path: "/settlement/income-document/list" });
            }
        }
    }
}
