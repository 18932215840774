import Vue from "vue";
import { Component } from "vue-property-decorator";
import SettlementIncomeDocumentItemServiceTypeRestModel from "../../../../models/rest/settlement/income-document/settlementIncomeDocumentItemServiceTypeRestModel";
import SettlementIncomeDocumentItemTaxTypeRestModel from "../../../../models/rest/settlement/income-document/settlementIncomeDocumentItemTaxTypeRestModel";
import SettlementIncomeDocumentRestModel from "../../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementIncomeDocumentItemRestModel from "../../../../models/rest/settlement//income-document/settlementIncomeDocumentItemRestModel";
import { BranchEnum } from "../../../../models/emums/branchEnum";


@Component({
    props: {
        currencyRate: {
            default: undefined
        },
        parentComponentName: {
            default: ""
        },
    }
})

export default class IncomeDocumentItemDialog extends Vue {
    currencyRate!: number | undefined;
    parentComponentName!: string;

    rules = {
        itemDescription: [
            () => {
                const error = this.validationError.Description != undefined ? this.validationError.Description[0] : "";
                return error == "" || error;
            }],
        descriptionDetailed: [
            () => {
                const error = this.validationError.DescriptionDetailed != undefined ? this.validationError.DescriptionDetailed[0] : "";
                return error == "" || error;
            }],
        itemQuantity: [
            () => {
                const error = this.validationError.Quantity != undefined ? this.validationError.Quantity[0] : "";
                return error == "" || error;
            }],
        itemUnit: [
            () => {
                const error = this.validationError.Unit != undefined ? this.validationError.Unit[0] : "";
                return error == "" || error;
            }],
        itemTaxRate: [
            () => {
                const error = this.validationError.TaxRate != undefined ? this.validationError.TaxRate[0] : "";
                return error == "" || error;
            }],
        itemPrice: [
            () => {
                const error = this.validationError.Price != undefined ? this.validationError.Price[0] : "";
                return error == "" || error;
            }],
        itemPricePln: [
            () => {
                const error = this.validationError.PricePln != undefined ? this.validationError.PricePln[0] : "";
                return error == "" || error;
            }],
        itemNetto: [
            () => {
                const error = this.validationError.Netto != undefined ? this.validationError.Netto[0] : "";
                return error == "" || error;
            }],
        itemNettoPln: [
            () => {
                const error = this.validationError.NettoPln != undefined ? this.validationError.NettoPln[0] : "";
                return error == "" || error;
            }],
        itemBrutto: [
            () => {
                const error = this.validationError.Brutto != undefined ? this.validationError.Brutto[0] : "";
                return error == "" || error;
            }],
        itemBruttoPln: [
            () => {
                const error = this.validationError.BruttoPln != undefined ? this.validationError.BruttoPln[0] : "";
                return error == "" || error;
            }],
        itemTax: [
            () => {
                const error = this.validationError.Tax != undefined ? this.validationError.Tax[0] : "";
                return error == "" || error;
            }],
        itemTaxPln: [
            () => {
                const error = this.validationError.TaxPln != undefined ? this.validationError.TaxPln[0] : "";
                return error == "" || error;
            }],
        itemTaxTypeId: [
            () => {
                const error = this.validationError.TaxTypeId != undefined ? this.validationError.TaxTypeId[0] : "";
                return error == "" || error;
            }],
    }

    get validationError(): any {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemValidationError;
    }

    get showDialog(): boolean {
        if (this.incomeDocument.branchId != undefined && this.item.description == "") {
            this.item.description = this.incomeDocumentItemServiceTypes[0] as SettlementIncomeDocumentItemServiceTypeRestModel as any;
        }
        return this.$store.direct.state.settlementIncomeDocument.showIncomeDocumentItemDialog;
    }

    get title(): string {
        const item = this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemDialogTitle;

        return item;
    }

    get isFromCorrection(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemDialogIsFromCorrection;
    }

    get item(): SettlementIncomeDocumentItemRestModel {
        const item = this.$store.direct.state.settlementIncomeDocument.incomeDocumentItem;

        if (item.price != undefined) {
            this.assignItemPricePln(item);
        }

        return item;
    }

    set item(val: SettlementIncomeDocumentItemRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItem(val);
    }

    get itemList() {
        if (this.isFromCorrection) {
            return this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemCorrectionList;
        }
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentItemList;
    }

    set itemList(val: SettlementIncomeDocumentItemRestModel[]) {
        if (this.isFromCorrection) {
            this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemCorrectionList(val);
        }
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemList(val);
    }

    get incomeDocument(): SettlementIncomeDocumentRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocument;
    }

    get incomeDocumentItemTaxTypes(): SettlementIncomeDocumentItemTaxTypeRestModel[] {
        const incomeDocumentItemTaxTypes = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentItemTaxTypes;
        if (incomeDocumentItemTaxTypes != undefined) {
            if (this.incomeDocument.branchId == BranchEnum.DE) {
                return incomeDocumentItemTaxTypes.filter((x: SettlementIncomeDocumentItemTaxTypeRestModel) => {
                    return x.branchId == BranchEnum.DE;
                });
            }
            else if (this.incomeDocument.branchId == BranchEnum.PL) {
                return incomeDocumentItemTaxTypes.filter((x: SettlementIncomeDocumentItemTaxTypeRestModel) => {
                    return x.branchId == BranchEnum.PL;
                });
            }
            else if (this.incomeDocument.branchId == BranchEnum.TR) {
                return incomeDocumentItemTaxTypes.filter((x: SettlementIncomeDocumentItemTaxTypeRestModel) => {
                    return x.branchId == BranchEnum.TR;
                });
            }
        }
        return incomeDocumentItemTaxTypes;
    }

    get incomeDocumentItemServiceTypes(): SettlementIncomeDocumentItemServiceTypeRestModel[] {
        let incomeDocumentItemServiceTypes = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentItemServiceTypes;
        if (incomeDocumentItemServiceTypes != undefined) {
            incomeDocumentItemServiceTypes = incomeDocumentItemServiceTypes.filter((x: SettlementIncomeDocumentItemServiceTypeRestModel) => {
                return x.id != 0 && x.id != -1;
            })

            if (this.incomeDocument.branchId == BranchEnum.DE) {
                return incomeDocumentItemServiceTypes.filter((x: SettlementIncomeDocumentItemServiceTypeRestModel) => {
                    return x.branchId == BranchEnum.DE;
                });
            }
            else if (this.incomeDocument.branchId == BranchEnum.PL) {
                return incomeDocumentItemServiceTypes.filter((x: SettlementIncomeDocumentItemServiceTypeRestModel) => {
                    return x.branchId == BranchEnum.PL;
                });
            }
            else if (this.incomeDocument.branchId == BranchEnum.TR) {
                return incomeDocumentItemServiceTypes.filter((x: SettlementIncomeDocumentItemServiceTypeRestModel) => {
                    return x.branchId == BranchEnum.TR;
                });
            }
        }
        return incomeDocumentItemServiceTypes;
    }

    itemTaxTypeChanged(item: SettlementIncomeDocumentItemRestModel) {
        const foundTaxType = this.incomeDocumentItemTaxTypes.find((x: SettlementIncomeDocumentItemTaxTypeRestModel) => {
            return x.id == item.taxTypeId;
        })

        if (foundTaxType != undefined) {
            this.item.taxRate = foundTaxType?.taxRate
        }
        this.calculateNettoBruttoAndTaxValue(item);
    }

    async assignItemPricePln(item: SettlementIncomeDocumentItemRestModel) {
        if (this.currencyRate != undefined) {
            item.pricePln = await this.$store.direct.dispatch.settlementCommon.roundValueToTwoPlaces(item.price * this.currencyRate);
        }
        else {
            item.pricePln = item.price;
        }
        this.calculateNettoBruttoAndTaxValueInPln(item);
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly({ evt: evt });
    }

    changeCommaToDot(val: any): number {
        return parseFloat(val.toString().replace(",", "."));
    }

    async calculateNettoBruttoAndTaxValue(item: SettlementIncomeDocumentItemRestModel) {
        if (item.price != undefined) {
            this.item.price = this.changeCommaToDot(item.price);
        }

        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValue({ item: item, parentComponentName: this.parentComponentName });
        this.calculateNettoBruttoAndTaxValueInPln(item);
        await this.$forceUpdate();
    }

    async calculateNettoBruttoAndTaxValueInPln(item: SettlementIncomeDocumentItemRestModel) {
        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValueInPln({ item: item, parentComponentName: this.parentComponentName });
        await this.$forceUpdate();
    }

    cancelClick() {
        this.$store.direct.dispatch.settlementIncomeDocument.isNotConfirmedIncomeDocumentItemDialog();
        this.item = {} as SettlementIncomeDocumentItemRestModel;
    }

    async saveClick() {
        this.item.isDocumentCorrection = this.incomeDocument.isDocumentCorrection;

        if (typeof (this.item.description) === "object") {
            const itemDescriptionCopy = this.item.description as SettlementIncomeDocumentItemServiceTypeRestModel;
            this.item.description = itemDescriptionCopy.description;
            this.item.serviceTypeId = itemDescriptionCopy.id;
        }
        else {
            const foundServiceType = this.incomeDocumentItemServiceTypes.find((x: SettlementIncomeDocumentItemServiceTypeRestModel) => {
                return x.description == this.item.description;
            })

            if (foundServiceType == undefined || foundServiceType == null) {
                this.item.serviceTypeId = undefined as any;
            }
        }

        this.item.taxRate = (this.item.taxRate == undefined || this.item.taxRate.toString().length == 0) ? 0 : this.item.taxRate as any;
        this.$forceUpdate();

        await this.$store.direct.dispatch.settlementIncomeDocument.validateIncomeDocumentItem({ item: this.item });

        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (Object.keys(this.validationError).length === 0) {
            if (this.$refs.form != undefined) {
                const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
                await this.$store.direct.dispatch.settlementIncomeDocument.isConfirmedIncomeDocumentItemDialog();

                const foundItem = this.itemList.find(x => {
                    return x == this.item;
                });
                if (foundItem != undefined) {
                    const index = this.itemList.indexOf(foundItem);
                    if (index != undefined && index > -1) {
                        this.item = foundItem;
                    }
                }
                else {
                    this.itemList.push(this.item)
                }

                this.item = {} as SettlementIncomeDocumentItemRestModel;
            }
        }
    }
}
